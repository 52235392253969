import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'

import { ScrollToTopOfPage, ContentfulSitePerson } from 'blog-components'
import Layout from '../components/Layout'

// eslint-disable-next-line
import SiteInformation from '../fragments/SiteInformationFragment'

class PageTemplate extends ScrollToTopOfPage {
  render() {
    const site = get(this, 'props.data.site')

    const title = get(this, 'props.data.person.title')
    const name = get(this, 'props.data.person.name')
    const description = get(
      this,
      'props.data.person.description.childMarkdownRemark.rawMarkdownBody'
    )
    const slug = get(this, 'props.data.person.slug')
    const fluidImage = get(this, 'props.data.person.image.fluid')
    const htmlAst = get(
      this,
      'props.data.person.shortBio.childMarkdownRemark.htmlAst'
    )

    return (
      <Layout>
        <div className="container fadein-3 page" data-emergence="visible">
          <ContentfulSitePerson
            site={site}
            title={title}
            name={name}
            description={description}
            slug={slug}
            fluidImage={fluidImage}
            imageSrcUrl={fluidImage.src}
            htmlAst={htmlAst}
          />
        </div>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query ContentfulPersonByPath($path: String!) {
    site {
      ...SiteInformation
    }
    person: contentfulPerson(slug: { eq: $path }) {
      name
      title
      slug
      description {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      shortBio {
        childMarkdownRemark {
          htmlAst
        }
      }
      image {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`
